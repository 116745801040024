<template lang="pug">
include ../../configs/template
div.authorizationPage
  v-card.mt-16.pa-0.authFormAs
    v-card-title.accent.d-flex.flex-row.justify-center.text-bold-600.v-card__title {{ $t('password-recovery') }}

    v-card-text
      +field('body.email', '"email"', '["required", "email"]').mt-2
      +field('body.username', '"username"', '["required", "alphaENText"]').mt-2
    v-card-actions.justify-center
      v-btn(color="secondary" to="/login") {{$t('btn.back')}}
      v-btn(color="primary" @click="setRecoveryPassword" :loading="isLoading") {{$t('btn.recovery')}}

</template>

<script>
import { recoveryPassword } from '@/mixins/validationRules'
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      isLoading: false,
      body: {
        email: null,
        username: null
      },
      SUCCESS_CODE: [200, 201]
    }
  },
  validations () { return recoveryPassword() },
  beforeDestroy () {
    this.body = {
      email: null,
      username: null
    }
  },
  methods: {
    ...mapActions(['recoveryPassword']),
    async setRecoveryPassword () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      const response = await this.recoveryPassword(this.body)
      this.isLoading = false
      if (this.SUCCESS_CODE.includes(response.code)) {
        this.$swal({
          icon: 'success',
          text: this.$t('gotLetterEmailWithLink', { email: this.body.email })
        }).then(() => this.$router.push('/login'))
      }
    }
  }
}
</script>
